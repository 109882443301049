









































import { post_change_pass } from "@/api";
import { VuetifyForm } from "@/shared/types";
import { eightChar, required } from "@/shared/validation";
import { Notif } from "@/store";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({ name: "change-password" })
export default class ChangePassword extends Vue {
  /* Constants */

  @Prop({ required: true }) readonly value!: string;

  validate = [eightChar, required];

  /* Variables */

  formData = {
    newPassword: "",
    confirmPassword: "",
  };

  visible = false;
  visConf = false;

  /* Computed */
  /* Methods */

  @Emit("submit")
  async submit(): Promise<void> {
    try {
      if (!(this.$refs.formData as VuetifyForm).validate) return;
      await post_change_pass({ user: this.value, ...this.formData });
      Notif.notif_success("Password Changed.");
      this.close();
    } catch (error) {
      Notif.notif_api_error(error); /* Error Message Unknown ? */
    }
  }

  @Emit("input")
  close(): null {
    (this.$refs.formData as VuetifyForm).reset();
    return null;
  }

  /* Life-cycle Methods */
}
